import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'src/store';
import { productIdSelector } from 'src/store/selectors/productSelector';
import { previousLocationSelector } from 'src/store/selectors/routerSelector';
import { selectedCompanyIdSelector, selectedCompanyNameSelector } from 'src/store/selectors/companySelector';
import { fetchSchemeListByCompanyAction } from 'src/store/slices/schemeSlice';

import { useIsDeferredRenderReady } from '@itm/shared-frontend/lib/hooks';

const useDataAccessPermission = (isSchemeLevel?: boolean) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isDeferredRenderReady = useIsDeferredRenderReady();
  const productId = useSelector(productIdSelector);
  const companyId = useSelector(selectedCompanyIdSelector);
  const companyName = useSelector(selectedCompanyNameSelector);
  const previousLocation = useSelector(previousLocationSelector);
  const [hasDataAccess, setHasDataAccess] = useState(true);
  const [isShowDataAccessForm, setIsShowDataAccessForm] = useState(false);

  const requestSchemeList = useCallback(async () => {
    if (!companyId) return;
    await dispatch(fetchSchemeListByCompanyAction(companyId));
  }, [companyId, dispatch]);

  const resetDataAccessState = () => {
    setHasDataAccess(true);
    setIsShowDataAccessForm(false);
  };

  useEffect(() => {
    if (!isSchemeLevel || !isDeferredRenderReady || previousLocation.pathname === pathname) return;

    requestSchemeList();
    // companyId dependency is disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeferredRenderReady, isSchemeLevel, pathname, previousLocation.pathname]);

  useEffect(() => {
    resetDataAccessState();
  }, [companyId]);

  return {
    pathname,
    companyId,
    companyName,
    productId,
    previousLocation,
    hasDataAccess,
    isShowDataAccessForm,
    isDeferredRenderReady,
    setHasDataAccess,
    setIsShowDataAccessForm,
    requestSchemeList,
    resetDataAccessState,
  };
};

export default useDataAccessPermission;
