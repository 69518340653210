import classNames from 'classnames';
import axios, { AxiosRequestConfig } from 'axios';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { RoutePath } from 'src/router';
import { generatePath } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { string, object, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { EditButton } from '@itm/shared-frontend/lib/components/buttons';
import { ServerErrorAdapter, TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';
import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import {
  DeferredTable,
  createColumnHelper,
  useSyncExternalFilters,
  useTanstackTableBasicKit,
} from '@itm/shared-frontend/lib/components/tanstack-table';

import { DataAccessPermission, isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';
import useDataAccessPermission from 'src/hooks/useDataAccessPermission';

import { clientPortalApi } from 'src/api';
import { getSchemeListByCompanyId } from 'src/api/biReporting/scheme';
import { getProjectListSearch, ProjectListSearchParams } from 'src/api/biReporting/project';

import { ProjectResponse, SelectOption, ServerError, TanstackTableChangeParams } from 'src/types';

type FormData = Pick<ProjectListSearchParams, 'schemeId'>;

const formSchema: ObjectSchema<FormData> = object({
  schemeId: string().label('Scheme').trim(),
});

function useTableFilters(companyId: string, setHasDataAccess: React.Dispatch<React.SetStateAction<boolean>>) {
  const [schemeOptions, setSchemeOptions] = useState<SelectOption[]>();

  const {
    data: [data, setData],
    pageCount: [pageCount, setPageCount],
    isLoading: [isLoading, setIsLoading],
    tableServerErrorMessages: [serverErrorMessages, setServerErrorMessages],
    globalServerErrorMessages: [globalServerErrorMessages],
    tableInstanceRef,
    fillFilterOptionsBase,
    tableChangeHandlerBase,
    cleanup,
  } = useTanstackTableBasicKit<ProjectResponse>();

  const { register, control, formState, setValue, resetField } = useForm<FormData>({
    resolver: yupResolver(formSchema),
  });
  const { startSyncExternalFilters } = useSyncExternalFilters({ control, tableInstanceRef });

  const CustomGlobalFilter = useMemo(
    () => (globalFilterElement: React.JSX.Element) => (
      <form className="is-fullwidth" name="ProjectListFiltersForm" noValidate>
        <div className="columns is-multiline">
          <div className="column is-2-widescreen is-4-desktop is-6-tablet">{globalFilterElement}</div>
          {schemeOptions?.length !== 1 && (
            <div className="column is-2-widescreen is-4-desktop is-6-tablet">
              <Field
                inputClassName={classNames('is-fullwidth', { 'is-loading': !schemeOptions })}
                field="dropdown"
                placeholder={!schemeOptions || schemeOptions.length ? 'Select a scheme' : 'No available schemes'}
                autoComplete="off"
                options={schemeOptions}
                register={register('schemeId')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled={!schemeOptions?.length}
                dropdownProps={{ isClearable: true }}
              />
            </div>
          )}
        </div>
      </form>
    ),
    [control, formState.errors, register, schemeOptions],
  );

  const fillSchemeOptions = useCallback(async () => {
    if (!companyId) {
      setSchemeOptions([]);
      return;
    }
    const options = await fillFilterOptionsBase(
      (config?: AxiosRequestConfig) => getSchemeListByCompanyId(companyId, config),
      setSchemeOptions,
    );

    if (options?.length === 1) setValue('schemeId', options[0].value);
  }, [companyId, fillFilterOptionsBase, setValue]);

  const tableChangeHandler = useCallback(
    async ({ externalFilters, ...tableChangeParams }: TanstackTableChangeParams<FormData>) => {
      if (!companyId) {
        setIsLoading(false);
        return;
      }
      const pageSize = tableChangeParams.pagination.pageSize;

      const params: ProjectListSearchParams = {
        ...new TanstackTableSearchParamsAdapter(tableChangeParams),
        companyId,
        schemeId: externalFilters.schemeId || undefined,
      };

      const requestData = (config?: AxiosRequestConfig) => getProjectListSearch(params, config);

      const catchOverride = (e: unknown) => {
        if (!(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
          setPageCount(0);
          setData([]);
        } else {
          const serverError = new ServerErrorAdapter(e as ServerError);
          setServerErrorMessages(serverError.combine());
        }
      };

      const data = await tableChangeHandlerBase({ pageSize, requestData, startSyncExternalFilters, catchOverride });
      if (!data) return;

      setHasDataAccess(true);
    },
    [
      companyId,
      setData,
      setPageCount,
      setIsLoading,
      setHasDataAccess,
      setServerErrorMessages,
      tableChangeHandlerBase,
      startSyncExternalFilters,
    ],
  );

  useEffect(() => {
    fillSchemeOptions();
    return () => {
      resetField('schemeId');
    };
  }, [fillSchemeOptions, resetField]);

  useEffect(() => () => cleanup(), [cleanup]);

  return {
    data,
    columns,
    pageCount,
    isLoading,
    tableInstanceRef,
    serverErrorMessages,
    globalServerErrorMessages,
    tableChangeHandler,
    CustomGlobalFilter,
  };
}

const columnHelper = createColumnHelper<ProjectResponse>();

const columns = [
  columnHelper.accessor('name', { header: 'Name' }),
  columnHelper.accessor('code', { header: 'Code' }),
  columnHelper.accessor('status', { header: 'Status' }),
  columnHelper.display({
    id: 'action',
    enableSorting: false,
    meta: { headerClassName: 'is-narrow' },
    cell: ({ row }) => {
      const { id: projectId, name } = row.original;
      const editProjectButtonLabel = `Edit ${name} project`;
      const editProjectRoute = generatePath(RoutePath.dataManagementProjectEditRoot, { projectId });

      return <EditButton aria-label={editProjectButtonLabel} title={editProjectButtonLabel} to={editProjectRoute} />;
    },
  }),
];

function ProjectList() {
  const { companyId, companyName, productId, hasDataAccess, setHasDataAccess } = useDataAccessPermission();

  const {
    data,
    columns,
    pageCount,
    isLoading,
    tableInstanceRef,
    serverErrorMessages,
    globalServerErrorMessages,
    tableChangeHandler,
    CustomGlobalFilter,
  } = useTableFilters(companyId, setHasDataAccess);

  const SuccessDataAccessButtonComponent = useMemo(
    () => () => (
      <button className="button is-interact" type="button" onClick={() => setHasDataAccess(true)}>
        Back to Project Search
      </button>
    ),
    [setHasDataAccess],
  );

  return (
    <>
      <h2 className="title mb-0">Project Search</h2>
      <hr />

      {hasDataAccess ? (
        <>
          <ServerErrorMessages messages={globalServerErrorMessages} />
          <ServerErrorMessages messages={serverErrorMessages} />

          <DeferredTable
            data={data}
            columns={columns}
            pageCount={pageCount}
            isLoading={isLoading}
            instanceRef={tableInstanceRef}
            onParamsChange={tableChangeHandler}
            CustomGlobalFilter={CustomGlobalFilter}
          />
        </>
      ) : (
        <DataAccessPermission
          companyId={companyId}
          companyName={companyName}
          clientPortalApiInstance={clientPortalApi}
          productId={productId}
          SuccessButtonComponent={SuccessDataAccessButtonComponent}
        />
      )}
    </>
  );
}

export default ProjectList;
