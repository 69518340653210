import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RoutePath } from 'src/router';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { PageLoading } from '@itm/shared-frontend/lib/components';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { TabLayout } from '@itm/shared-frontend/lib/components/layout';
import type { TabOption } from '@itm/shared-frontend/lib/components/layout/TabLayout';

import ProjectDetails from './ProjectDetails';
import ProjectRAGDetails from './ProjectRAGDetails';

import { useSetBreadcrumbsNames } from 'src/components/BreadcrumbsTrail';

import { getProjectById } from 'src/api/biReporting/project';

import { ProjectResponse, ServerError, ServerFormErrors } from 'src/types';

type RouteParams = {
  projectId: string;
};

type Props = Readonly<{
  isEdit: boolean;
}>;

const tabLayoutOptions: TabOption[] = [
  { path: 'detail', tabTitle: 'Project Detail' },
  { path: 'rag-detail', tabTitle: 'Project RAG Detail' },
];

function ProjectTabs({ isEdit }: Props) {
  const { projectId } = useParams<RouteParams>();
  const [project, setProject] = useState<ProjectResponse>();
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  useSetBreadcrumbsNames({
    routePath: RoutePath.dataManagementProjectEditRoot,
    value: project ? project.name || 'Project' : null,
  });

  const fetchProject = useCallback(async () => {
    if (!projectId) return;
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setServerErrorMessages([]);
    setIsLoading(true);
    try {
      const result = await getProjectById(projectId, { signal: abortController.signal });
      setProject(result.data);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    } finally {
      setIsLoading(false);
      abortControllerSet.delete(abortController);
    }
  }, [abortControllerSet, projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  useEffect(() => () => cleanup(), [cleanup]);

  return (
    <>
      <h2 className="title mb-0">{project?.name || 'View Project'}</h2>
      <hr />

      <ServerErrorMessages messages={serverErrorMessages} />
      {isLoading ? (
        <PageLoading />
      ) : (
        <TabLayout tabLayoutOptions={tabLayoutOptions}>
          <Routes>
            <Route index element={<Navigate to="details" replace={true} />} />

            <Route path="detail" element={<ProjectDetails project={project} isEdit={isEdit} />} />
            <Route path="rag-detail" element={<ProjectRAGDetails ragReport={project?.ragReport} isEdit={isEdit} />} />

            <Route path="*" element={<Navigate to="./" />} />
          </Routes>
        </TabLayout>
      )}
    </>
  );
}

export default ProjectTabs;
