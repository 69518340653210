export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Data Management
  dataManagementRoot: '/data-management',
  dataManagementProjectRoot: '/data-management/project',
  dataManagementProjectList: '/data-management/project/list',
  dataManagementProjectEditRoot: '/data-management/project/:projectId/edit',
  dataManagementProjectEditDetails: '/data-management/project/:projectId/edit/detail',
  dataManagementProjectEditRagDetails: '/data-management/project/:projectId/edit/rag-detail',

  // Report
  reportRoot: '/report',
  reportList: '/report/list',
  reportCreate: '/report/create',

  // Analytics & Reports
  analyticsAndReports: '/analytics-and-reports',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
