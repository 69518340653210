import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';

import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import { ProjectResponse } from 'src/types';

type Props = Readonly<{
  project: ProjectResponse | undefined;
  isEdit: boolean;
  isView?: boolean;
}>;

const formSchema = object().shape({
  name: string().trim().label('Name'),
  code: string().trim().label('Code'),
  status: string().trim().label('status'),
});

function ProjectDetails({ project }: Props) {
  const { register, control, formState, setValue, setError, clearErrors } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { serverErrorMessages } = useFormErrors(setError, clearErrors);

  useEffect(() => {
    if (project) {
      setValue('name', project.name);
      setValue('code', project.code);
      setValue('status', project.status);
    }
  }, [project, setValue]);

  return (
    <>
      <ServerErrorMessages messages={serverErrorMessages} />
      <form name="projectDetailForm" noValidate>
        <div className="card mb-5">
          <div className="columns is-multiline">
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Name"
                field="input"
                placeholder="Name"
                autoComplete="off"
                register={register('name')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled
              />
            </div>
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Code"
                field="input"
                placeholder="Code"
                autoComplete="off"
                register={register('code')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled
              />
            </div>
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Status"
                field="input"
                placeholder="Status"
                autoComplete="off"
                register={register('status')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ProjectDetails;
