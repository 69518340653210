import { Routes, Route, Navigate } from 'react-router-dom';

import ProjectsRoutes from './projects';

function DataManagementRoutes() {
  return (
    // "/data-management/*"
    <Routes>
      <Route index element={<Navigate to="project" replace={true} />} />

      <Route path="project/*" element={<ProjectsRoutes />} />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default DataManagementRoutes;
